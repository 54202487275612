import React, { useEffect } from 'react'
import SectionType from 'models/types/ts/sectionType'
import classNames from 'classnames'
import AnimatedText from 'layouts/animated-text/AnimatedText2'
import BackgroundImage from 'features/background-image'
import ReactMarkdown from 'utils/markdown'
import { HeadingMedium, HeadingRegular } from 'layouts/typography'
import { Container, Section, Visibility } from 'layouts'
import { Button, Target } from '@ccpgames/eve-web/components'
import s from './Vanguard.module.scss'
import VgLogo from './vgLogo'

interface Props {
    section: SectionType
}

const Vanguard = ({ section }: Props): JSX.Element => {
    const cx = classNames.bind(s)

    return (
        <>
            {section && (
                <Container xlWide>
                    <Section className={s.vanguard}>
                        <BackgroundImage
                            className={s.inner}
                            url={section.imageFile.url}
                        >
                            <div className={s.content}>
                                <Visibility
                                    direction="fadeUp"
                                    transitionDelay="0.2s"
                                >
                                    <div className={s.copy}>
                                        <HeadingRegular
                                            as="h2"
                                            className={s.title}
                                        >
                                            <AnimatedText>
                                                {section.headline}
                                            </AnimatedText>
                                        </HeadingRegular>

                                        <ReactMarkdown source={section.body} />
                                    </div>
                                    <Target color="#FFF" className={s.button}>
                                        <Button
                                            href={section.buttonUrl}
                                            // className={style.btn}
                                            backgroundColor="#FFF"
                                            style={{
                                                color: 'black',
                                                height: '60px',
                                            }}
                                        >
                                            {section.buttonText}
                                        </Button>
                                    </Target>
                                </Visibility>
                            </div>
                            <div className={s.image}>
                                <Visibility
                                    direction="fadeUp"
                                    transitionDelay="0.5s"
                                    // duration="2s"
                                >
                                    <VgLogo />
                                </Visibility>
                            </div>
                        </BackgroundImage>
                    </Section>
                </Container>
            )}
        </>
    )
}

export default Vanguard
