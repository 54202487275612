import React, { useState, useEffect } from 'react'
import { Formik, Field, Form } from 'formik'
import { Translate, TranslateFunction } from 'utils/localize'
import { ClipLoader } from 'react-spinners'
import { useSelector } from 'react-redux'
import { getSessionId } from 'utils/session-id/session-id'
import Button from 'layouts/button'
import Input from 'layouts/form/components/input2'
import {
    AgeVerificationProviderType,
    AgeVerificationRedirectRequestModel,
} from 'models/age-verification-model'
import JourneyIdManager from 'proto/helpers/journeyid'
import { getLocationPayload, getQuery } from 'lib/location/selectors'
import { getAgeVerificationRedirectUrl } from 'services/api/signup/age-verification'
import PageLocationType from 'models/page-location-type'
import getConfig, { isClient } from 'config/web'
import VerificationManager from 'packages/authentication/components/verification-callback/VerificationManager'
import { GlobalState } from 'types'
import { FormValuesType, SelectOptionType } from './types'
import ageVerificationValidation from './ageVerificationValidation'
import SelectField from './components/select-field'
import FormGroup from '../signup/components/form-group'
import LoginLink from '../signup/components/login-link'
import s from './AgeVerification.module.scss'

interface Props {
    provider: AgeVerificationProviderType
}

const AgeVerification = ({ provider }: Props): JSX.Element => {
    const jidManager = new JourneyIdManager()

    const [methodOptions, setMethodOptions] = useState<Array<SelectOptionType>>(
        []
    )

    const [showLoadingButton, setShowloadingButton] = useState(false)
    const query = useSelector((state: GlobalState) => getQuery(state))
    const location: PageLocationType = useSelector((state: GlobalState) =>
        getLocationPayload(state)
    )

    const verificationManager = new VerificationManager()

    const { methods } = provider

    // Change property names for select component
    const getOptions = (): Array<SelectOptionType> => {
        return methods.map((method) => ({
            name: `signup.ageVerification.identityMethod-${method.identifier}`,
            value: method.identifier,
        }))
    }

    const initialValues: FormValuesType = {
        email: '',
        method: methods[0].identifier,
    }

    function getCallbackUrl(): string {
        const { webBaseUrl } = getConfig()
        const { page } = location
        const recruitLink = query?.invc ? `?invc=${query.invc}` : ''
        return `${webBaseUrl}/${page}${recruitLink}`
    }

    function redirectToPayletter(email: string, method: string): void {
        if (isClient) {
            const { webBaseUrl, signupServiceUrl } = getConfig()

            const redirectRequest: AgeVerificationRedirectRequestModel = {
                country: 'kr',
                userId: email,
                state: getSessionId(),
                isMobileClient: false,
                journeyId: jidManager.getJourneyId(),
                method,
                returnUrl: `${signupServiceUrl}/web/redirect?returnUrl=${webBaseUrl}/verification-callback?redirectUrl=${getCallbackUrl()}`,
                cancelUrl: `${webBaseUrl}/cancel`,
                siteUrl: 'https://eveonline.com',
            }

            verificationManager.setSessionStorage(
                verificationManager.sessionEmail,
                email
            )

            getAgeVerificationRedirectUrl(redirectRequest).then((data) => {
                if (data && data.redirect) {
                    window.location.href = data.redirect
                }
            })
        }
    }

    function getTranslatedMethodOptions(
        translate: TranslateFunction
    ): Array<SelectOptionType> {
        // Overriding each name with localized name
        for (let i = 0; i < methodOptions.length; i += 1) {
            methodOptions[i].name = translate(methodOptions[i].name).toString()
        }

        return methodOptions
    }

    useEffect(() => {
        // Only call getOptions once in here and put in state, then things don't get rerendered, e.g the dropdown doesn't change on submit
        setMethodOptions(getOptions())
    }, [])

    return (
        <div className={s.verification}>
            <Translate>
                {({ translate }) => (
                    <>
                        <p className={s.text}>
                            {translate(`signup.ageVerification.description`)}
                        </p>
                        <Formik
                            initialValues={initialValues}
                            validateOnChange={false}
                            validationSchema={ageVerificationValidation(
                                translate
                            )}
                            onSubmit={(values) => {
                                setShowloadingButton(true)
                                redirectToPayletter(values.email, values.method)
                            }}
                        >
                            <Form className={s.form}>
                                <FormGroup>
                                    <Field
                                        name="email"
                                        type="email"
                                        labelText={translate(`signup.email`)}
                                        component={Input}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <span className={s.label}>
                                        {translate(
                                            `signup.ageVerification.identityVerificationLabel`
                                        )}
                                    </span>
                                    <Field
                                        name="method"
                                        component={SelectField}
                                        options={getTranslatedMethodOptions(
                                            translate
                                        )}
                                    />
                                </FormGroup>
                                <FormGroup slim className={s.submit}>
                                    <Button
                                        type="submit"
                                        as="button"
                                        className={s.btn}
                                    >
                                        {showLoadingButton ? (
                                            <div className={s.loader}>
                                                <ClipLoader
                                                    size={30}
                                                    color="#fff"
                                                    loading
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                {translate(
                                                    `signup.ageVerification.startVerificationButtonText`
                                                )}
                                            </>
                                        )}
                                    </Button>
                                </FormGroup>
                            </Form>
                        </Formik>
                    </>
                )}
            </Translate>
            <LoginLink />
        </div>
    )
}

export default AgeVerification
